import { cn } from "@/lib/utils";

import { PropsWithChildren } from "react";

interface TypographyProps extends PropsWithChildren {
  className?: string;
}

export function TypographyH1({ children, className }: TypographyProps) {
  return (
    <h1 className={cn(" text-gray-500 text-4xl font-extrabold tracking-tight scroll-m-20 lg:text-5xl", className)}>
      {children}
    </h1>
  );
}

export function TypographyH2({ children, className }: TypographyProps) {
  return <h2 className={cn("text-gray-500 md:text-[38px] text-[26px] leading-9", className)}>{children}</h2>;
}
export function TypographyH3({ children, className }: TypographyProps) {
  return <h3 className={cn("text-gray-500 text-2xl  tracking-tight scroll-m-20", className)}>{children}</h3>;
}
export function TypographyH4({ children, className }: TypographyProps) {
  return <h4 className={cn(" text-gray-500 text-xl  tracking-tight scroll-m-20", className)}>{children}</h4>;
}
export function TypographyH5({ children, className }: TypographyProps) {
  return <h4 className={cn("text-gray-500 md:text-[18px] text-[14px] leading-6", className)}>{children}</h4>;
}
export function TypographyP({ children, className }: TypographyProps) {
  return <p className={cn("text-gray-500 md:text-[20px] text-[16px] leading-5", className)}>{children}</p>;
}

export function TypographyBlockquote({ children, className }: TypographyProps) {
  return <blockquote className={cn("  pl-6 mt-6 italic border-l-2", className)}>{children}</blockquote>;
}

export function TypographyLead({ children, className }: TypographyProps) {
  return <p className={cn("  text-xl text-muted-foreground", className)}>{children}</p>;
}

export function TypographySmall({ children, className }: TypographyProps) {
  return <small className={cn("  text-lg  font-medium leading-none", className)}>{children}</small>;
}

export function TypographyXS({ children, className }: TypographyProps) {
  return <small className={cn("  text-xs  font-medium leading-none", className)}>{children}</small>;
}

export function TypographyLarge({ children, className }: TypographyProps) {
  return <div className={cn("  text-lg ", className)}>{children}</div>;
}

export function TypographyMuted({ children, className }: TypographyProps) {
  return <p className={cn("  text-sm text-muted-foreground", className)}>{children}</p>;
}
