import { cn } from "@/lib/utils";
import { MotionValue, motion } from "framer-motion";
import { PropsWithChildren } from "react";

interface ParallaxItemProps extends PropsWithChildren {
  y: MotionValue<number>;
  className?: string;
}

export const ParallaxItem = ({ y, className, children }: ParallaxItemProps) => {
  return (
    <motion.div className={cn("z-[100]", className)} style={{ y }}>
      {children}
    </motion.div>
  );
};
