"use client";

import { Button } from "@/components/ui/button";
import { IconEyeQuestion } from "@tabler/icons-react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { Search } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { MenuToggle } from "./ham-menu-toggle";

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const controls = useAnimation();

  const toggleNavbar = () => setIsOpen(!isOpen);

  const navItems = [
    { icon: Search, label: "Pricing", link: "/pricing" },
    { icon: IconEyeQuestion, label: "Faq", link: "/faq" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 600 && !isScrolled) {
        setIsScrolled(true);
        controls.start("scrolled");
      } else if (scrollPosition <= 600 && isScrolled) {
        setIsScrolled(false);
        controls.start("normal");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolled, controls]);

  const navVariants = {
    normal: {
      justifyContent: "space-between",
    },
    scrolled: {
      justifyContent: "flex-start",
    },
  };

  const linkVariants = {
    normal: { x: 125 },
    scrolled: { x: 0 },
  };

  const buttonVariants = {
    normal: { y: 70, opacity: 0 },
    scrolled: { y: 0, opacity: 1 },
  };

  return (
    <div className="sticky top-0 z-50 px-4 ">
      <div className="absolute md:w-[584px] overflow-hidden w-[calc(100%-16px)] top-2 md:top-8  py-2  left-1/2 -translate-x-1/2 bg-[hsla(0,0%,93%,0.72)] backdrop-blur-xl transform-gpu rounded-[2rem]">
        <div className="flex items-center justify-between h-10 px-2">
          <Link href="/" className="-ml-2">
            <Image src="/logo/playe-black.svg" alt="playe" width={60} height={60} quality={100} />
          </Link>
          <div className="block md:hidden">
            <MenuToggle toggle={toggleNavbar} isOpen={isOpen} />
          </div>
          <div className="items-center hidden gap-4 md:flex">
            <motion.div
              className="flex items-center h-full gap-4 font-semibold text-black"
              variants={linkVariants}
              initial="normal"
              animate={controls}
            >
              <Link href="/pricing">Pricing</Link>
              <Link href="/auth/signin" className="">
                Log in
              </Link>
            </motion.div>
            <motion.div variants={buttonVariants} initial="normal" animate={controls}>
              <Button variant="secondary" className="h-full rounded-full">
                Build on Playe
              </Button>
            </motion.div>
          </div>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <nav className="px-4 py-2">
                {navItems.map((item, index) => (
                  <motion.div
                    key={item.label}
                    className="flex transition-opacity ease-out w-fit hover:opacity-80 focus-visible:ring-4 focus-visible:ring-blue-200/50 text-[20px] py-1 font-semibold text-black"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Link href={item.link}>{item.label}</Link>
                  </motion.div>
                ))}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: navItems.length * 0.1 }}
                >
                  <Button size="lg" variant="secondary" className="w-full my-4 rounded-full">
                    Get Started
                  </Button>
                </motion.div>
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default MobileNavbar;
