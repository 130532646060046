"use client";

import { cn } from "@/lib/utils";
import Lenis from "@studio-freight/lenis";
import { useScroll, useTransform } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { ArrowRight } from "lucide-react";
import { ParallaxItem } from "@/components/Framer/Parallax";
import { TypographyH1, TypographyH2, TypographySmall } from "@/components/Typography";
import { Button } from "@/components/ui/button";

type Props = {};

const PropelYourBrand = (props: Props) => {
  const gallery = useRef(null);
  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  const { scrollYProgress } = useScroll({
    target: gallery,
    offset: ["start end", "end start"],
  });
  const { height } = dimension;
  const y = useTransform(scrollYProgress, [0, 3], [200, -height]);

  useEffect(() => {
    const lenis = new Lenis();

    const raf = (time: any) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    const resize = () => {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", resize);
    requestAnimationFrame(raf);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className="bg-[#121212]">
      <div className="relative flex flex-col items-center rounded-b-[50px] bg-white pb-[10vmin] py-20 px-4 sm:px-6 overflow-hidden ">
        <ul ref={gallery} className="flex justify-center flex-shrink-0 gap-12 py-10 scale-75 lg:scale-100 -mb-80">
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-20">
            <LogoItem href="#" src="/social/discord.svg" title="Discord" />
            <LogoItem href="#" src="/social/spotify.svg" title="Spotify" imgClassName="p-4" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-10">
            <LogoItem href="#" src="/social/ulysses.svg" title="Slack" imgClassName="p-4" />
            <LogoItem href="#" src="/social/activecampaign.svg" title="Slack" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-20">
            <LogoItem href="#" src="/social/twitter.svg" title="Twitter" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex-shrink-0 mt-10">
            <LogoItem href="#" src="/social/zapier.svg" title="Zapier" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex-shrink-0">
            <LogoItem href="#" src="/social/stripe.svg" title="Stripe" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex-shrink-0">
            <LogoItem href="#" src="/social/slack.svg" title="Slack" imgClassName="p-4" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex-shrink-0 mt-10">
            <LogoItem href="#" src="/social/insta.svg" title="Instagram" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-20">
            <LogoItem href="#" src="/social/facebook.svg" title="Facebook" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-10">
            <LogoItem href="#" src="/social/ia.svg" title="iA Writer" />
            <LogoItem href="#" src="/social/youtube.svg" title="Youtube" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-20">
            <LogoItem href="#" src="/social/feedly.svg" title="Feedly" />
            <LogoItem href="#" src="/social/g-analytics.svg" title="Google Analytics" imgClassName="p-4" />
          </ParallaxItem>
          <ParallaxItem y={y} className="flex flex-col flex-shrink-0 gap-12 mt-10">
            <LogoItem href="#" src="/social/git.svg" title="GitHub" />
            <LogoItem href="#" src="/social/netlify.svg" title="Netlify" />
            <LogoItem href="#" src="/social/letsencrypt.svg" title="Let's Encrypt" imgClassName="p-4" />
          </ParallaxItem>
        </ul>
        <div className="z-10 mx-auto mt-6 max-w-8xl">
          <div className="grid gap-[4vmin] grid-cols-12 items-center">
            <div className="flex flex-col items-center col-span-12 text-center">
              {/* <TypographyXS className="font-bold text-blue-400 uppercase">CREATE WINNERS</TypographyXS> */}
              <TypographyH1 className="mt-6 font-bold text-black text-nowrap">
                Propel your brand <br />
                to new heights.
              </TypographyH1>
              <div className="max-w-lg mx-auto mt-2 mb-6">
                <p className="text-zinc-500 ">
                  Customer acquisition just become <br />a lot more playful.
                </p>
              </div>
              <div className="flex gap-2 text-center md:gap-4">
                <Button size="lg" variant="secondary" className="px-4 rounded-full text-[16px]">
                  Book demo
                </Button>
                <Button
                  size="lg"
                  variant="outline"
                  className="text-black pr-2 pl-4 hover:bg-zinc-50 hover:border-zinc-200 hover:text-black bg-transparent text-[16px] rounded-full border-zinc-200"
                >
                  See our plans
                  <div className="p-1 ml-2 rounded-full bg-zinc-100">
                    <ArrowRight size={16} color="black" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropelYourBrand;

interface LogoItemProps {
  href?: string;
  src: string;
  title: string;
  imgClassName?: string;
}

export const LogoItem = ({ href, src, title, imgClassName }: LogoItemProps) => {
  return (
    <div className="relative flex flex-col items-center justify-center group ">
      <a
        className="flex flex-col items-center justify-center w-24 h-24 overflow-hidden border shadow-2xl rounded-3xl hover:shadow-3xl border-neutral-200"
        href={href || "#"}
      >
        <Image src={src} alt={title} width={160} height={160} className={cn("", imgClassName)} />
      </a>
      <p className="absolute text-xs leading-none text-white px-3 py-2 bg-zinc-900 rounded-lg shadow-sm pointer-events-none whitespace-nowrap bottom-full opacity-0 transition-all group-hover:opacity-100 group-hover:bottom-[110%]">
        {title}
      </p>
    </div>
  );
};
